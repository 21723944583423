<script lang="ts" setup>
const isShowSupportModal = defineModel<boolean>('isShowSupportModal', { default: false });

const { contacts } = storeToRefs(useSiteStore());
</script>

<template>
  <BaseModal
    v-model="isShowSupportModal"
    title="Есть вопрос?"
    @close="isShowSupportModal = false"
  >
    <section class="space-y-2 text-sm mb-6">
      <p>Горячая линия работает ежедневно <b>с 11 до 20</b> по Московскому времени</p>

      <p>
        Телефон:
        <a
          :href="`tel:${contacts.phone}`"
          class="font-bold"
        >
          {{ contacts.phone }}
        </a>
      </p>

      <a
        :href="contacts.tgLink"
        class="flex items-center gap-1"
        rel="nofollow noopener"
        target="_blank"
      >
        Задать вопрос в <b>Telegram</b>

        <SvgoDialog
          :font-controlled="false"
          class="h-4"
          filled
        />
      </a>

      <p>
        Эл. почта:
        <a
          class="font-bold"
          href="mailto:order@hellride.ru"
        >
          order@hellride.ru
        </a>
      </p>
    </section>
  </BaseModal>
</template>
